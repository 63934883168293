import { SET_DATA, SET_DIA } from "./actionTypes";

export const setData = (data) => {
  //console.log("SET_DATA: ", data);
  return { type: SET_DATA, payload: { data } };
};

export const setDia = (data) => {
  //console.log("SET_DIA: ", data);
  return { type: SET_DIA, payload: { data } };
};

export const tryUpdate = (dia, user) => async (dispatch) => {
  const datos = { dia, user };

  const response = await fetch(`https://jcalvez.info/apps/merlin/update/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(datos),
  });

  const res = await response.json();

  if (res.dia.casinos.length > 0) {
    console.log("DATA ", res.dia.casinos);
    //dispatch(authLogin(res.user));
    dispatch(setDia(res.dia));
  } else {
    console.log("Update Faliure");
  }
};
