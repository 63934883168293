import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import SysName from "./SysName";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authLogout } from "../../redux/auth/actions";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: "wrap",
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  separador: {
    flexGrow: 1,
  },
  email: {
    paddingRight: theme.spacing(2),
  },
}));

export default function NavBar() {
  const classes = useStyles();
  const { user } = useSelector((state) => state.authReducer);

  console.log("User ", user);

  const dispatch = useDispatch();
  let history = useHistory();

  const handloLogout = () => {
    dispatch(authLogout());
    history.replace("/");
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="sticky">
        <Container maxWidth="lg">
          <Toolbar>
            <FlashOnIcon className={classes.icon} />
            <Typography variant="h6" color="inherit" noWrap>
              <SysName />
            </Typography>
            <div className={classes.separador}></div>
            {user.lastName ? (
              <>
                <Typography
                  variant="h6"
                  color="inherit"
                  noWrap
                  className={classes.email}
                >
                  {/*user.lastName + ", " + user.firstName*/}
                </Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handloLogout}
                >
                  Salir
                </Button>
              </>
            ) : (
              <Button
                variant="contained"
                color="secondary"
                component={RouterLink}
                to="/auth"
              >
                Entrar
              </Button>
            )}
          </Toolbar>
        </Container>
      </AppBar>
    </React.Fragment>
  );
}
