import { SET_DATA, SET_DIA } from "./actionTypes";

const initialState = {
  data: [],
};

export default function dataReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA: {
      const { data } = action.payload;
      return { data: JSON.parse(JSON.stringify(data)) };
    }
    case SET_DIA: {
      const { data } = state;
      const dia = data.find(
        (dia) => dia.diadejuego === action.payload.data.diadejuego
      );
      dia.status = "0";
      dia.casinos.forEach((casino, idx) => {
        casino.terminado = "1";
      });
      return { data: JSON.parse(JSON.stringify(data)) };
    }
    default:
      return state;
  }
}
