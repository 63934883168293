import React, { useState } from "react";
import { useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Casino from "./Casino";
import { useDispatch } from "react-redux";
import { tryUpdate, setData } from "../../redux/data/actions";

export default function ListaDeDias() {
  const { data } = useSelector((state) => state.dataReducer);
  const { user } = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
  const [miDia, setMiDia] = useState(null);

  console.log("data ", data);

  const handleDia = (dia) => {
    console.log(dia.diadejuego);
    setMiDia(dia);
  };

  const unHandleDia = () => {
    console.log("Soltando dia");
    setMiDia(null);
  };

  const ajustar = () => {
    dispatch(tryUpdate(miDia, user));
    //dispatch(setDia(miDia));
    unHandleDia();
  };

  const handleCambio = (miCasino) => {
    const nuevoCasinos = miDia.casinos.map((casino) =>
      miCasino.id_casino == casino.id_casino ? miCasino : casino
    );

    const nuevoDia = { ...miDia, casinos: nuevoCasinos };

    const nuevaData = data.map((dia) =>
      dia.diadejuego == miDia.diadejuego ? nuevoDia : dia
    );

    setMiDia(nuevoDia);

    dispatch(setData(nuevaData));
  };

  return (
    <>
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end">
          {miDia == null ? (
            <>
              {data.map((dia, idx) => (
                <Grid item key={"dia-" + idx} xs={12} sm={6} md={4}>
                  <Button
                    fullWidth
                    variant="contained"
                    color={dia.status === "0" ? "default" : "primary"}
                    onClick={() => handleDia(dia)}
                  >
                    {dia.diadejuego}
                  </Button>
                </Grid>
              ))}
            </>
          ) : (
            <>
              {miDia.casinos.map((casino, idx) => (
                <Grid key={"ca-" + idx} item xs={12} sm={6} md={4}>
                  <Casino casino={casino} seter={handleCambio} />
                </Grid>
              ))}
              <Grid container spacing={2} item xs={12} sm={6} md={4}>
                <Grid item xs={6} sm={6} md={6}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => ajustar()}
                  >
                    Ajustar
                  </Button>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="default"
                    onClick={() => unHandleDia()}
                  >
                    Cancelar
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    </>
  );
}
