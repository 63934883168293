import React from "react";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/core/styles";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  iconNotDone: {
    color: "red",
  },
  iconDone: {
    color: "green",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "25ch",
  },
  toTheRight: {
    textAlign: "right",
  },
}));

export default function Casino(props) {
  const classes = useStyles();
  const casino = props.casino;

  const handleChange = (e) => {
    console.log("Handle Change");

    props.seter({ ...props.casino, ventasala: e.target.value });
  };

  return (
    <Card elevation={10}>
      <CardHeader
        title={casino.casino}
        titleTypographyProps={{ align: "center" }}
        action={null}
        className={classes.cardHeader}
      />
      <CardContent>
        <ul>
          <Typography component="li" variant="subtitle1" align="center">
            Terminado:{" "}
            {casino.terminado === "1" ? (
              <CheckIcon className={classes.iconDone} />
            ) : (
              <ClearIcon className={classes.iconNotDone} />
            )}
          </Typography>
          <Typography component="li" variant="subtitle1" align="center">
            Revisado:{" "}
            {casino.revisado === "1" ? (
              <CheckIcon className={classes.iconDone} />
            ) : (
              <ClearIcon className={classes.iconNotDone} />
            )}
          </Typography>
        </ul>
      </CardContent>
      <CardActions>
        <FormControl fullWidth className={classes.margin} variant="outlined">
          <InputLabel
            htmlFor={`outlined-adornment-amount1-${casino.id_casino}`}
          >
            Venta en Tragamonedas
          </InputLabel>
          <OutlinedInput
            inputProps={{ min: 0, style: { textAlign: "right" } }} // the change is here
            id={"outlined-adornment-amount1-" + casino.id_casino}
            value={casino.ventatragamonedas}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            labelWidth={180}
            disabled
          />
        </FormControl>
      </CardActions>
      <CardActions>
        <FormControl fullWidth className={classes.margin} variant="outlined">
          <InputLabel
            htmlFor={"outlined-adornment-amount2-" + casino.id_casino}
          >
            Venta en Ruleta Electronica
          </InputLabel>
          <OutlinedInput
            inputProps={{ min: 0, style: { textAlign: "right" } }} // the change is here
            id={"outlined-adornment-amount2-" + casino.id_casino}
            value={casino.ventarulelec}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            labelWidth={200}
            disabled
          />
        </FormControl>
      </CardActions>
      <CardActions>
        <FormControl fullWidth className={classes.margin} variant="outlined">
          <InputLabel
            htmlFor={"outlined-adornment-amount3-" + casino.id_casino}
          >
            Venta en Sala
          </InputLabel>
          <OutlinedInput
            inputProps={{ min: 0, style: { textAlign: "right" } }} // the change is here
            id={"outlined-adornment-amount3-" + casino.id_casino}
            value={casino.ventasala}
            onChange={handleChange}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            labelWidth={100}
            disabled={false}
          />
        </FormControl>
      </CardActions>
    </Card>
  );
}
